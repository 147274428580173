import React  from 'react';
import { Row, Col } from 'react-bootstrap';
import { useApplicationContext } from './appcontext';
import {parseISO as dateparse} from 'date-fns';
import {format as dateformat} from 'date-fns/esm';
import {nb} from 'date-fns/esm/locale'

const ProductLine = React.memo( ( { product, largecar=false}) => {
    if (product) {
       let price  = largecar ? product.priceLargeCar : product.price;
       return <span className='productLine'>{product.displayname}: {price} NOK</span>
    }
   return null;
});

function getProduct(prodkey, prodlist) {
    const lookup = prodkey.toString();
    for (const [otherkey, prodvalue] of Object.entries(prodlist)) {
        if (lookup === otherkey || prodvalue.id === prodkey) {
            return prodvalue;
        }
    }
    return null
}

export const PriceWidget = React.memo( () => {
    const appcontext = useApplicationContext();
    if (!appcontext.bookingdata['allprices']) {
        return "<p></p>";
    }

    let peakpricing = false;
    let surcharge = 0;
    if (appcontext.depdate) {
        console.log("We have a departuredate %j", appcontext.depdate);
        const peakperiods=appcontext.bookingdata['peakperiods'];
        if (peakperiods) {
            for (let i=0; i<peakperiods.length; i++) {
                let from = dateparse(peakperiods[i].from);
                let to = dateparse(peakperiods[i].to);
                if (appcontext.depdate >= from && appcontext.depdate <= to) {
                    peakpricing = true;
		    surcharge = (appcontext.days && appcontext.days < 8) ? 10 : 20;
		    break;
                }
            }
        }
    } else {
	    console.log("No departure date yet");
    }
    console.log("Peak pricing? %j", peakpricing);


    // Product pricing
    let largecar = appcontext.largecar;
    let prodlist = appcontext.bookingdata.allprices.products
    let productprice = 0;
    let dropinprice = 0;
    let unknownproductprice = true;
    let products = [];

    for (const [, pvalue] of Object.entries(appcontext.programs.formdata)) {
        let prod = getProduct(pvalue, prodlist);
        if (prod) {
            let thisprice = parseFloat(largecar ? prod.priceLargeCar : prod.price);
            if (!isNaN(thisprice)) {
                productprice += thisprice;
                unknownproductprice = false;
            }
            products.push(prod);
        }
    }

    // Parking prices
    let parkingprice = 0;
    let parkingpriceknown = false;
    let discount = 0;

    // Check for dates here, but from nov 1.
    let parkingtable = appcontext.bookingdata.allprices.newparking;

    // Find our entry
    if (!appcontext.openreturn && appcontext.days) {
        parkingpriceknown = true;
        let pentry = null;
        let previous = null;
        for(var i=0;i<parkingtable.length;i++) {
            previous = pentry;
            pentry = parkingtable[i];
//            console.log("days are "  + appcontext.days  +  " checking product with max days " + pentry.maxdays);
            if (appcontext.days>pentry.maxdays) continue;
            break;
        }
        if (pentry && pentry.perWeek === "1" && previous) {
            var weeks = Math.ceil((appcontext.days-previous.maxdays)/7);
            parkingprice = (1*previous.price) +((1*pentry.price)*weeks);
        } else if (pentry) {
            parkingprice = (1*pentry.price);
        }
    }
    let surchargedprice = parkingprice;
    if (peakpricing) {
	    surchargedprice = Math.round(parkingprice + (parkingprice * (surcharge / 100)));
    }

    if (appcontext.dropin) {
        dropinprice = Math.round(1*appcontext.bookingdata.allprices.products['Dropin']['price']);
        productprice += dropinprice;
        unknownproductprice=false;
    }

    if (appcontext.user && appcontext.user.totaldiscount && !appcontext.aapenretur) {
        let dis = appcontext.user.totaldiscount/100;
        discount = -1 * (surchargedprice * dis).toFixed(0);
    }
    if (! discount && appcontext.companydiscount && !appcontext.aapenretur) {
        let dis = appcontext.companydiscount / 100;
        discount = -1 * (surchargedprice * dis).toFixed(0);
    }

    let totalprice = 0;
    if (!unknownproductprice && parkingpriceknown) {
        totalprice = productprice + surchargedprice + discount;
    }

    return (
       <div className="ordersummary">
        <Row>
         <Col>
        <h2>Ordre&shy;sammendrag</h2>
        <p> Avreise: { appcontext.depdate ? dateformat(appcontext.depdate, "do MMM yyyy 'kl.' HH:mm", {locale: nb}) : "-" } </p>
        <p> Retur:  {appcontext.retdate && !appcontext.openreturn  ? dateformat(appcontext.retdate, "do MMM yyyy 'kl.' HH:mm", {locale: nb}) : "-" } </p>
        { appcontext.openreturn ? <p>Åpen retur </p> : [] }
        { parkingpriceknown ? <p> Parkering {appcontext.days} {appcontext.days >1 ? "dager" : "dag" }: {surchargedprice} NOK </p> : []  }
        { appcontext.dropin ? <p> Dropin {dropinprice} NOK </p> : [] }
        { products.map( (prod) => <p><ProductLine product={prod} largecar={largecar} /></p> ) }

        { discount ? <p>{"Rabatt: " +  discount + " NOK"} </p> : [] }
        { totalprice ? <p>{"Å betale: " + totalprice + " NOK" }</p> : [] }

<p style={{fontSize: ".8rem"}}>
Det kan tilkomme et tillegg på ca 30-90 kr ved hjemkomst. Dette betales på automat ved utkjøring eller på <a href="https://www.autopay.no"  rel="noreferrer"  target="_blank">www.autopay.io</a> innen 48 timer. Les våre vilkår for mer utfyllende informasjon om dette.
</p>


         </Col>
        </Row>



       </div>);
});
