import React  from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useApplicationContext } from '../appcontext';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {nb} from 'date-fns/esm/locale';
import {parseISO as dateparse} from 'date-fns';
registerLocale('nb', nb);
setDefaultLocale(nb);



export const Step1 = React.memo( ({alerthandler, formdata, setValidStep}) => {
    const navigate = useNavigate();
    const appcontext = useApplicationContext();
    let [validated, setValidated] = React.useState(false);

    let fullperiods = [];
    if (appcontext && appcontext['bookingdata'] && appcontext['bookingdata']['fullperiods']) {
       let fpfull = appcontext['bookingdata']['fullperiods'];
       for (let i=0; i<fpfull.length; i++) {
	let todate = dateparse(fpfull[i]['to']);
	let fromdate = dateparse(fpfull[i]['from']);
	if (todate > new Date()) {
	  let entry = {};
	  entry['end'] = todate;
	  entry['start'] = fromdate;
	  fullperiods.push(entry);
	}
      }
    }


    function validate(then) {
        let ok = true;
        let inputok = document.getElementById('username').checkValidity();;
        if (!appcontext.username || !inputok) {
            alerthandler.addAlert(1, 'danger', "Ugyldig e-post", "emailalerts");
            ok = false;
        }
        if (!formdata.formdata.servicedate) {
            alerthandler.addAlert(1, 'danger', "Angi datoen du har bestillet service for", "datealerts");
            ok = false;
        }
        then(ok);
    }

    function nextStep() {
        window.sessionStorage.setItem('form_step1', JSON.stringify(formdata.formdata));
        const success = function (validation) {
            setValidated(true);
            if (!validation) {
                console.log("Invalid form");
                setValidStep(1, false);
                return false;
            } else {
                console.log("Valid form");
                console.log("Setting valid step to 2");
                setValidStep(1, true);
                return navigate("../2");
            }
        };
        return validate(success);
    }

    const handleEmailChange = (e) => {
        appcontext.setUsername(e.target.value);
        formdata.setFormData('email', e.target.value);
        alerthandler.removeAlertGroup('emailalerts');
        if (!e.target.value) {
            setValidated(false);
        }
    };
    const handleEmailBlur = (e) => {
        if (e.target.value) {
            setValidated(true);
        } else {
            setValidated(false);
        }
    }
    const handleEmailFocus = (e) => {
        setValidated(false);
    }

  const blurDates = (e) => {
      alerthandler.removeAlertGroup('datealerts');
      console.log("Blurring dates");
   }

  const setServiceDate = (date) => {
    // This handles the "00:00" thing a bit
    let now = new Date();
    if (date && date < now) {
        date = now;
    }
    appcontext.setServiceDate(date);
    formdata.setFormData('servicedate', date);

    // Reset departuredate
    if (appcontext.depdate && appcontext.depdate > date) {
        console.log("departuredate is after servicedate, resetting");
        appcontext.setDepDate(null);
        appcontext.formdata['step2'].setFormData('departuredate', null);
        appcontext.setDropin(false);
        setValidStep(1, false);
    }
    if (appcontext.retdate && appcontext.retdate < date) {
        console.log("returndate is before servicedate, resetting");
        appcontext.setRetDate(null);
        appcontext.formdata['step2'].setFormData('returndate', null);
        setValidStep(1, false);
    }

  }


  const getDefaultServiceDate = () => {
      return formdata.formdata['servicedate'];
  };

  return (
  <div   className={"step step1"} >
  <Row>
   <Col>
   <p>Før du kan reservere parkering hos vår partner Flightpark må du ha bestilt tid for
service hos Porsche Center Gardermoen. Har du ikke gjort dette enda kan du
kontakte oss på <a href="tel:63900911">63 900 911</a>.</p>
   </Col>
  </Row>
   <Row>
    <h2>E-post</h2>
    <Col style={{position:"relative"}}>
    <Form.Group className={ (validated ?  "was-validated" : "not-validated") }>
      <Form.Label> Din e-post: </Form.Label>
      <Form.Control  readOnly={ appcontext.user ? true : false } id="username" value={appcontext.username}  
	  onFocus={handleEmailFocus}
	  onBlur={handleEmailBlur} onChange={ handleEmailChange }
                  required placeholder="Skriv inn e-post" name="email" type="email" />
      <Form.Control.Feedback type="invalid" tooltip>
                  Du må bruke en gyldig e-postaddresse
      </Form.Control.Feedback>

    </Form.Group>
    </Col>
   </Row>

   <h2>Dato for service</h2>
  <Row>
   <Col>
    <Form.Group >
    <Form.Label for="servicedate"> Angi dato for service: </Form.Label>
       <DatePicker 
          id="servicedate" locale="nb" name="servicedate"  
          placeholderText={ "Angi avtalt dato for service"}
          strictParsing
          className="form-control"
          autoComplete="off"
          selected={getDefaultServiceDate()}
          openToDate={formdata.formdata['servicedate'] ? formdata.formdata['servicedate'] : null }
          minDate={new Date() }
          dateFormat="do MMM yyyy"
	  excludeDateIntervals={fullperiods}
          onChange={setServiceDate}
          onCalendarClose={ blurDates }
          fixedHeight 
       />
    </Form.Group>
    </Col>
  </Row>

   <Row>
    <Col>
     <Button  className="me-2 mb-2" onClick={() => nextStep()}>
      Fortsett til bestilling av parkering
     </Button>

    </Col>
   </Row>


  </div>);
});

