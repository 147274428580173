import React  from 'react';
import { useNavigate } from "react-router-dom";
import { Form,  Button,  Row, Col } from 'react-bootstrap';
import { useApplicationContext } from '../appcontext';
import { callFlightpark } from '../restcalls';


export const Step3= React.memo( ({ alerthandler, formdata, setShowlogin, setValidStep}) => {
    const navigate = useNavigate();
    const appcontext = useApplicationContext();

    const [showcompanycode, setShowCompanyCode] = React.useState(false);
    const [companywarningtext, setCompanyWarningText] = React.useState("");
    const [companycodevalidated, setCompanyCodeValidated] = React.useState(false);

    let showlogin = appcontext.userexists && !appcontext.user;

    function validate(then) {
        let ok = true;

        if (!formdata.formdata['firstname'] || !formdata.formdata['lastname']) {
           alerthandler.addAlert(3, 'danger', "Vennligst fyll ut for- og etternavn", "personaliaalerts");
           ok = false;
        }
        if (!formdata.formdata['mobile']) {
           alerthandler.addAlert(3, 'danger', "Vennligst fyll ut mobiltelefonnr", "mobilealerts");
           ok = false;
        }
        if (!formdata.formdata['licenseplate'] || !formdata.formdata['carbrand']) {
           alerthandler.addAlert(3, 'danger', "Vennligst fyll ut biltype og registreringsnummer ", "caralerts");
           ok = false;
        }
        if (!appcontext.userexists && !appcontext.user && !formdata.formdata['password']) {
           alerthandler.addAlert(3, 'danger', "Vennligst velg et passord", "passwordalerts");
           ok = false;
        }
        if (!appcontext.userexists && !appcontext.user && formdata.formdata['password'] && !formdata.formdata['password2']) {
           alerthandler.addAlert(3, 'danger', "Vennligst bekreft passordet", "passwordalerts");
           ok = false;
        }
        if (formdata.formdata['password'] && formdata.formdata['password2']
             && formdata.formdata['password'] !== formdata.formdata['password2']) {
           alerthandler.addAlert(3, 'danger', "Passordene stemmer ikke overens", "passwordalerts");
           ok = false;
        }
        if (!appcontext.user && formdata.formdata['companycode'] && !companycodevalidated) {
            return checkCompanyCode(formdata.formdata['companycode'], function (ok) {
                if (!ok) {
                    alerthandler.addAlert(3, 'danger', "Firmakoden kan ikke verifiseres");
                }
                return then(ok);
            });
        }

         return then(ok);
    }

    function nextStep() {
        window.sessionStorage.setItem('form_step3', JSON.stringify(formdata.formdata));
        const success = function (validation) {
            if (!validation) {
                console.log("Invalid form 3");
                setValidStep(3, false);
                return false;
            } else {
                console.log("Valid form 3");
                console.log("Setting valid step to 4");
                setValidStep(3, true);
                return navigate("../4");
            }
        };
        return validate(success);
    }

    const checkCompanyCode = (code, continuefunc) => {
        let c = code.trim();
        if (c === companycodevalidated) {
            setCompanyWarningText("");
            return;
        }
        if( !c || appcontext.user ) {
            setCompanyWarningText("");
            return;
        }
        callFlightpark('checkcompanycode', {'email': appcontext.username, 'code': c, 'fpid' : appcontext.fpid },
         function (success) {
              appcontext.setCompanyDiscount(1*success['discount']);
              console.log("Setting discount for %j", success);
             setCompanyWarningText(success['msg']);
             setCompanyCodeValidated(code);
             if (continuefunc) return continuefunc(true);
         },
         function (failure) {
             console.log("feil tho: vi har data: %j", failure);
             appcontext.setCompanyDiscount(0);
             setCompanyWarningText(failure['msg']);
             setCompanyCodeValidated("");
             if (continuefunc) return continuefunc(false);
         },
         function (error) {
             console.log("en error: vi har data: %j", error);
             appcontext.setCompanyDiscount(0);
              setCompanyCodeValidated("");
              setCompanyWarningText("Kunne ikke verifisere firmakoden.");
              if (continuefunc) return continuefunc(false);
         });
    };


    // Initialize form if necessary
    React.useEffect( () => {
        if (appcontext.user) {
            let userdata = {};
            let change = false;
            let keys = ['firstname', 'lastname', 'mobile', 'licenseplate', 'carbrand'];

            if (!appcontext.user.carbrand.match(/porsche/i)) {
                keys = ['firstname', 'lastname', 'mobile' ];
            }

            keys.forEach( function (k) {
                if (!formdata.formdata[k]) {
                    console.log("Not in formdata " + k);
                    userdata[k] = appcontext.user[k];
                    change = true;
                } else {
                    console.log(k +  " exists: " + formdata.formdata[k]);
                }
            });
            if (change) {
                console.log("Setting form data userdate %j ", change, userdata);
                formdata.setFormData(userdata);
            } else {
                console.log("No change to userdata so we are not setting the form data");
            }
        }
        // Ensure we don't do passwords for live users
        if (!appcontext.user && appcontext.userexists) {
            console.log("This user exists, so we need to remove the passwords");
            formdata.removeFormData(['password', 'password2']);
        }
        if (!appcontext.user && formdata.formdata['companycode']) {
            setShowCompanyCode(true);
        }
    }, 
    // Only do this if user changes, ignore the formdata changes 
    // eslint-disable-next-line
    [appcontext.user, appcontext.userexists ]);

    // Used for iterating
    let cars = [];
    let i = 0;
    if (appcontext.user && appcontext.user.cars) {
        cars = appcontext.user.cars.filter( (c) => c.carbrand.match(/porsche/i) );
    }

    const setMyCar = (e) => {
        alerthandler.removeAlertGroup('caralerts'); 
        let index = e.target.value;
        if (index>0) {
            let newdata = { 
                'carbrand': cars[index]['carbrand'], 
                'licenseplate': cars[index]['licenseplate']
            };
            formdata.setFormData(newdata);
            e.target.value= -1
        }
    };

    const doLogin = (e) => {
        console.log("clkck");
        setShowlogin(true);
        console.log("Log in guy!");
    }


    return (
    <>
     <div   className={"step step3 user-information"} >

    <Row>
     <Col>
    <h1> Informasjon om bestiller   </h1>
     </Col>
      <Button style={{"display": showlogin ? "block" : "none" }} className="fetchPersonalia me-2 mb-2" onClick={doLogin}>
        Logg inn for å hente dine personalia
     </Button>
    </Row>

     <Row>
      <Col>
        <Form.Group >
	  <Form.Label for="f-name">Fornavn:</Form.Label>
	   <Form.Control placeholderText="Fornavn" type="text" 
                         value={formdata.formdata['firstname']} required id="firstname" name="firstname" 
                         onChange={(e) => {alerthandler.removeAlertGroup('personaliaalerts');  formdata.setFormData('firstname', e.target.value ) } } />
        </Form.Group>
     </Col>
     <Col>
        <Form.Group >
	  <Form.Label for="l-name">Etternavn:</Form.Label>
	   <Form.Control placeholderText="Etternavn"  type="text" value={formdata.formdata['lastname']} 
                         required id="lastname" name="lastname"
                         onChange={(e) => { alerthandler.removeAlertGroup('personaliaalerts'); formdata.setFormData('lastname', e.target.value ) } }
                         />
        </Form.Group>
     </Col>
    </Row>

    <Row>
     <Col>
      <Form.Label for="mobile"> Mobiltelefon:</Form.Label>
       <Form.Control placeholderText="Mobiltelefonnr" id="mobile" name="mobile" required
                     value={formdata.formdata['mobile']}
                     onChange={(e) => { alerthandler.removeAlertGroup('mobilealerts'); formdata.setFormData('mobile', e.target.value )  } }
                     />
    </Col>
    </Row>

    <Row style={{ 'display': appcontext.user && cars && cars.length > 1 ? "flex" : "none" }}>
     <Col>
        <Form.Select id="bilvelger" label="Mine biler"
          onChange={setMyCar}  >
          <>
	    <option selected value="-1" >Mine biler</option>
            { cars.map( (c) => 
               <option key={i.toString} value={i++}>{c.carbrand} {c.licenseplate}</option>
               )
            }
          </>
        </Form.Select>
     </Col>
    </Row>

    <Row>
     <Col>
      <Form.Label for="carbrand"> Modell:</Form.Label>
       <Form.Control placeholderText="Modell" id="carbrand" name="carbrand" required
                     value={formdata.formdata['carbrand']}
                      autoComplete="off"
                       list="porschecars"
                     onChange={(e) => { alerthandler.removeAlertGroup('caralerts'); formdata.setFormData('carbrand', e.target.value ) } }
                     />
     </Col>
     <Col>
      <Form.Label for="licenseplate"> Registreringsnummer:</Form.Label>
       <Form.Control placeholderText="Registreringsnr" id="licenseplate" name="licenseplate" required
                     value={formdata.formdata['licenseplate']}
                      autoComplete="off"
                     onChange={(e) => { alerthandler.removeAlertGroup('caralerts'); formdata.setFormData('licenseplate', e.target.value ) } }
                     />
     </Col>
    </Row>

    <Row style={{display: appcontext.userexists ? "none" : "flex" }} >
     <Col>
      <Form.Label for="password"> Velg passord:</Form.Label>
       <Form.Control placeholderText="Velg passord" id="password" name="password" required
                     type="password"
                     value={formdata.formdata['password']}
                      autoComplete="off"
                     onChange={(e) => { alerthandler.removeAlertGroup('passwordalerts'); formdata.setFormData('password', e.target.value )} }
                     />
     </Col>
     <Col>
      <Form.Label for="password2"> Bekreft passord:</Form.Label>
       <Form.Control placeholderText="Bekreft passord" id="password2" name="password2" required
                     type="password"
                     value={formdata.formdata['password2']}
                      autoComplete="off"
                     onChange={(e) => { alerthandler.removeAlertGroup('passwordalerts'); formdata.setFormData('password2', e.target.value ) } }
                     />
     </Col>
    </Row>

    <Row style={{'display': (appcontext.userexists && appcontext.user && appcontext.user.companyaccount) ? "none" : "flex" }} >
    <Col className="companycodecol">
     <button className='link-button' 
     style={{'display': (appcontext.userexists && appcontext.user && appcontext.user.companyaccount) || showcompanycode ? "none" : "inline-block" }} id="companycodetrigger" 
        onClick={(e) => setShowCompanyCode(true)} >
        Har du firmakode?
     </button> 
      <div className="companycode" style={{'display': showcompanycode ? "block" : "none" }}>
      <Form.Label for="companycode"> Firmakode:</Form.Label>
       <Form.Control placeholderText="Firmakode" id="companycode" name="companycode" required
                     value={formdata.formdata['companycode']}
                      autoComplete="off"
                     onChange={(e) => formdata.setFormData('companycode', e.target.value ) }
                     onBlur={(e) => checkCompanyCode(e.target.value) }
                     />
      <div style={{display: (companywarningtext ? "block" : "none" )}}  className="companywarning" > <strong>{companywarningtext}</strong> </div>
     </div>
     </Col>
    </Row>
    <Row style={{ 'visibility': showcompanycode ? "visible" : "hidden" }}>
     <Col>
      <div class='companycodeinfo' style={{marginBottom: "10px"}} >
			<a href="https://www.flightpark.no/parkering-gardermoen/bedriftsavtale/" target="_blank"  rel="noreferrer" >
				Om firmaavtaler/-koder.
			</a>
      </div>
     </Col>
    </Row>

    <Row>
     <Col>
           <Button  className="me-2 mb-2" onClick={nextStep}>
       Gå videre
     </Button>
     </Col>
    </Row>

<datalist id="porschecars">
 { appcontext.porschelist ? appcontext.porschelist.map( (el) => <option key={el}>{el}</option> ) : [] }
</datalist>

</div>
</>

    );
});

