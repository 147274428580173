import React  from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

export const useAlertHandler = () => {
    let [alerts, setAlerts] = React.useState([]);
    function addAlert(step, type, something, alertgroup) {
        let key = step.toString();
        let existing = alerts.find(element => element.step === key && element.content === something);
        if (existing) {
            return;
        }
        let boxed = {step: key, type:type, content:something, group:alertgroup};
        setAlerts(old => [...old, boxed]);
    }
    function removeAlert(something) {
        let newalerts = alerts.filter((el) => el.content !== something.content );
        setAlerts(newalerts);
    }
    function removeAlertGroup(alertgroup) {
        let existing = alerts.find(element => element.group === alertgroup );
        if (existing) {
            let newalerts = alerts.filter((el) => el.group !== alertgroup );
            setAlerts(newalerts);
        }
    }
    function clearAlerts(step) {
        if (!step) {
            setAlerts([]);
        } else {
            let newalerts = alerts.filter((el) => el.step !== step);
            setAlerts(newalerts);
        }
    }
    return { alerts: alerts, addAlert: addAlert, removeAlert: removeAlert, removeAlertGroup: removeAlertGroup, clearAlerts: clearAlerts };
};

export const Alerts = React.memo( ({alerthandler, step}) => 
       <div id="alertholder">
        <Row>
         <Col>
         {
           alerthandler.alerts.map( (thealert) => 
               (thealert.step === step ?
                 <Alert variant={thealert.type}  onClose={() => alerthandler.removeAlert(thealert)} dismissible>
                    {thealert.content}
                 </Alert> : "") 
            )
       }
          </Col>
        </Row>
       </div>
);

