import React  from 'react';
import { useApplicationContext } from './appcontext';
import { callFlightpark } from './restcalls';
import {parseISO} from 'date-fns';

export function Init () {
   // Before we do anything, init the application
  let appcontext = useApplicationContext();
  React.useEffect(() => {
          if (!appcontext.init) {

          /* Some globals defined by FP  */
          const flightsjs = document.createElement("script");
          flightsjs.src = "https://www.flightpark.no/js/flights.js";
          document.body.appendChild(flightsjs);
          /* End globals */

          console.log("Fetching bookingdata");

          fetch('https://www.flightpark.no/booking/bookingdata.php?fp_iam=THE APPKEY')
//          fetch('https://www.flightpark.no/booking/bookingdata.php?fp_iam=THE APPKEY')
            .then((response) => response.json())
            .then((data) => {
		/* IOK FIXME for testing full periods *
		data['fullperiods'].push({id: 99, from: "2023-01-04 00:00:00", to: "2023-01-04 06:59:00", why: "test"});
		data['capacityfullperiods'].push({id: 99, from: "2023-01-10 00:00:00", to: "2023-01-10 06:59:00", why: "test"});
		data['receptioncapacityfullperiods'].push({id: 99, from: "2023-01-08 00:00:00", to: "2023-01-08 06:59:00", why: "test"});
		*/
                appcontext.setBookingData(data);
             })
            .catch((error) => {
                // IOK FIXME INVALIDATE EVERYTHING AND DO ERROR PAGE
                console.error(error);
            }
          );


          callFlightpark('init', [], function (data) {
                  appcontext.setFpid(data['nonce']);
                  appcontext.setInit(true);
                  if (data['bookingcustomer']) {
                      if (data['bookingcustomer']['companyaccount'] === "0") {
                        data['bookingcustomer']['companyaccount'] = 0;
                      }
                      appcontext.setUsername(data['bookingcustomer']['email']);
                      appcontext.setUser(data['bookingcustomer']);
                  } else {
                      appcontext.setUsername(null);
                      appcontext.setUser(null);
                  }

                  let steps = ['step1', 'step2','step3', 'step4', 'step5'];
                  for (let i = 0; i<steps.length; i++) {
                      let stored = window.sessionStorage.getItem('form_'+steps[i]);
                      let obj = null;
                      try {
                         obj = stored ? JSON.parse(stored) : null;
                      } catch (error) {
                          console.log("session form data corrupted");
                          window.sessionStorage.removeItem('form_'+steps[i]);
                      }
                      if (obj && obj['servicedate']) {
                             console.log("restore service " + obj['servicedate']);
                             obj['servicedate'] = parseISO(obj['servicedate']);
                      }
                      if (obj && obj['departuredate']) {
                             console.log("restore dep " + obj['departuredate']);
                             obj['departuredate'] = parseISO(obj['departuredate']);
                      }
                      if (obj && obj['returndate']) {
                             console.log("restore return " + obj['returndate']);
                             obj['returndate'] = parseISO(obj['returndate']);
                      }
                      if (obj) {
                          console.log("Restoring form data on init for " + steps[i]);
                          appcontext.formdata[steps[i]].setFormData(obj);
                      }
                      // Set values from step1 if we have them
                      if (obj && steps[i] === 'step1') {
                             if (obj['servicedate']) {
                                 appcontext.setServiceDate(obj['servicedate']);
                             }
                             if (data['bookingcustomer']) {
                                 obj['email'] = data['bookingcustomer']['email'];
                             } else if (obj['email']) {
                                 // "Setting username from storage
                                 appcontext.setUsername(obj['email']);
                             }
                      }
                      if (obj && steps[i] === 'step2') {
                             if (obj['departuredate']) {
                                 appcontext.setDepDate(obj['departuredate']);
                             }
                             if (obj['returndate']) {
                                 appcontext.setRetDate(obj['returndate']);
                             }
                             if (obj['aapenretur']) {
                                 appcontext.setOpenReturn(true);
                             }

                             if (!obj['aapenretur'] && obj['departuredate'] && obj['returndate']) {
                                      let retdate = obj['returndate'];
                                      let depdate = obj['departuredate'];
                                      let cycles = (retdate-depdate)/(1000*60*60*24);
                                      let days = Math.ceil(cycles);
                                      appcontext.setDays(days);
                              }

                      }
                  }
          });

          } else {
            // Does not happen
          }
          /* we actually only depend on one element here and it runs only once so */
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [appcontext.init]);
  return (
    <div id="initdiv" data-fpid={appcontext.fpid}></div>
    );
}
