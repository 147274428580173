
export const porschelist = [
'Porsche 718',
'Porsche 911',
'Porsche Cayenne',
'Porsche Panamera',
'Porsche Macan',
'Porsche Taycan',
'Annen Porsche'
];
