import React  from 'react';

// a hook/object used to store/update form fields
export const useFormData = (obj) => {
    if (!obj) obj = {};
    let [formdata, setFormDataInternal] = React.useState(obj);

    // IOK INSTEAD OF THIS, USE MAYBE UPDATER FUNCTIONS?
	// setFormDataInternal( (f) =>  f[key] = value  );
	// These should then be queued, so..
    const setFormData = (key, value) => {
        if (typeof(value) == "undefined" && typeof(key) == "object") {
            let newformdata = {...formdata, ...key};
            setFormDataInternal( newformdata );
        } else {
            setFormDataInternal({...formdata, [key]: value});
        }
    }

   const removeFormData = (keys) => {
       let newformdata = {...formdata};
       for (let i=0; i<keys.length;i++) {
           delete newformdata[keys[i]];
       }
       console.log("New form data: %j", newformdata);
       setFormDataInternal(newformdata);
   }

    const clearFormData = () => {
        setFormDataInternal({});
    }

    return { formdata: formdata, setFormData: setFormData, removeFormData: removeFormData, clearFormData: clearFormData};
}



