import React  from 'react';
import {Modal} from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

export const TermsConditionsPopup = React.memo(({showterms, setShowTerms}) => {
    const [content, setContent] = React.useState({__html: "loading.."});

     React.useEffect( () => {
         console.log("Getting termses!");
         fetch('https://www.flightpark.no/booking/cors.php?do=getterms&fp_iam=THE APPKEY')
            .then((response) => response.text())
            .then ((textcontent) => setContent({__html: sanitizeHtml(textcontent)}))
            .catch((error) => {
                // Just print an error message? Make link to terms/conds?
                console.error("error in fetch " + error);
            });
     },
        []
     );

  return (
      <Modal size="lg" show={showterms} fullscreen='md-down'  onHide={() => setShowTerms(false)} >
        <Modal.Body>
         <div dangerouslySetInnerHTML={content} />
        </Modal.Body>
      </Modal>
  );
});
