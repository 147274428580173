import Cookies from 'js-cookie'
import axios from "axios";
import {format as dateformat} from 'date-fns/esm';

export function callFlightpark(action, input=false, successhandler=null, failurehandler=null, errorhandler=null) {
            let sid = Cookies.get('fp_sid');
            let data = { fp_iam: "THE APPKEY"  };
            const headers = { "accept": "application/json", "Content-Type": "application/x-www-form-urlencoded"} ;
            if (sid) {
                headers['X-FP-SID'] = sid;
            }
            data['do'] = action;
            if (input) {

            }
            for (const prop in input) {
                data[prop] = input[prop];
            }

            console.log("CAlling flightpark with " + action);
            axios
                .post("https://www.flightpark.no/booking/cors.php", data, { headers: headers })
                .then((response) => {
                        let sid = response['data']['sid'];
                        if (sid){
                        console.log("Setting cookie for call to " + data['do']);
                        Cookies.set('fp_sid', sid, { expires: 60 * 60 * 1000 });
                        } else {
                          console.log("No sid, response is %j", response);
                        }
                        if (response["data"]['ok']) {
                            if (successhandler) successhandler(response['data']);
                        } else {
                            if (failurehandler) failurehandler(response['data']);
                        }
                        })


            .catch((error) => {
                    console.log("Something error");
                if (!errorhandler) {
                    console.log("ERROR %j", error);
                    alert(error);
                } else {
                    errorhandler(error);
                }
                    });
}


export function completeorder(appcontext, andthen) {

    let user = appcontext.user;
    let formdata = appcontext.formdata;

    let complete  = {
        ...formdata['step1']['formdata'],
        ...formdata['step2']['formdata'],
        ...formdata['step3']['formdata'],
        ...formdata['step4']['formdata']
    };
    complete['ordermode'] = 'regular';

    // Ensure this is set
    complete['email'] = appcontext.username;

    // Original form formatted dates weirdly so
    complete['departuredate.orig'] = complete['departuredate'];
    complete['returndate.orig'] = complete['returndate'];
    complete['servicedate.orig'] = complete['servicedate'];

    let servicedate = complete['servicedate'];
    let departuredate = complete['departuredate'];
    let returndate = complete['aapenretur'] ? null : complete['returndate'];

    complete['servicedate']  = dateformat(servicedate, "d'/'M'/'yyyy");

    complete['departuredate'] = dateformat(departuredate, "d'/'M'/'yyyy");
    complete['departuretime'] = dateformat(departuredate, "HH:mm");

    complete['returndate'] =  returndate ? dateformat(returndate, "d'/'M'/'yyyy") : "";
    complete['returntime'] = returndate ? dateformat(returndate, "HH:mm") : "";

    complete['aapenretur'] = complete['aapenretur'] ? 1 : 0;

    complete['program[]'] = 36;
    complete['specs[36]'] = "Kunden har serviceavtale " + complete['servicedate'];

    // Here we should fold in the keys of appcontext.programs.formdata and any specs for the same. 
    // We are also ignoring the large car.


    complete['comment'] = complete['comment'] + "\nKunden har avtale med Porsche Center Gardermoen " + complete['servicedate'];

    // Used for cors stuff
    complete['fp_iam'] = 'THE APPKEY';

    // disable the changing of passwords for logged-in or existing users
    if (user || appcontext.userexists) {
        delete complete['password'];
        delete complete['password2'];
    }


    console.log("Complete is %j", complete);

    console.log(" sending to fp: %j", complete);

    let unknownfail = { 'ok': 0, 'msg': "En ukjent feil har oppstått", 'params': {} };

    let testsuccess = { 'ok': 1, 'msg': "Simulering av vellykket bestilling", 'params': {}, 'order' : {'id': 17 }};
    let testfail = { 'ok': 0, 'msg': "Simulering av mislykket bestilling", 'params': {} };
    if (appcontext.simulated === 'success') {
        return andthen(testsuccess);
    }
    if (appcontext.simulated === 'failure') {
        return andthen(testfail);
    }

    // Det er allerede registrert en bruker med e-postaddressen
    let sid = Cookies.get('fp_sid');
    const headers = { "accept": "application/json", "Content-Type": "application/x-www-form-urlencoded"} ;
    headers['X-FP-SID'] = sid;

    axios
        .post("https://www.flightpark.no/booking/ajaxAddOrder.php", complete, { headers: headers })
        .then((response) => {
            console.log("Response %j", response);
            if (!response['data']) {
                return andthen(unknownfail);
            }
            if (response['data']['sid']) {
               console.log("Setting cookie for admin-ajax call");
               Cookies.set('fp_sid', response['sid'], { expires: 60 * 60 * 1000 });
            }
            if (response['data']['session'] && response['data']['session']['bookingcustomer']) {
               console.log("Setting the booking customer again");
               appcontext.setUser(response['data']['session']['bookingcustomer']);
            }
            return andthen(response['data']);
       })
       .catch((error) => {
            console.log("Something error %j", error);
            return andthen(unknownfail);
            });

}
