import React  from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useApplicationContext } from '../appcontext';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { startOfDay } from 'date-fns';
import {nb} from 'date-fns/esm/locale';
import {parseISO as dateparse} from 'date-fns';
registerLocale('nb', nb);
setDefaultLocale(nb);



export const Step2 = React.memo( ({alerthandler, formdata, setValidStep}) => {
    const navigate = useNavigate();
    const appcontext = useApplicationContext();
    let [returnflightok, setReturnFlightOk] = React.useState(true);
    let [zerowarning, setZeroWarning] = React.useState(false);
   
    let dropinprice = 0;
    if (appcontext.bookingdata['allprices']) {
        dropinprice = Math.round(1*appcontext.bookingdata.allprices.products['Dropin']['price']);
    }

    let deliveryfull = [];
    if (appcontext && appcontext['bookingdata'] && appcontext['bookingdata']['capacityfullperiods']) {
      let fpfull = appcontext['bookingdata']['capacityfullperiods'];
      for (let i=0; i<fpfull.length; i++) {
	let todate = dateparse(fpfull[i]['to']);
	let fromdate = dateparse(fpfull[i]['from']);
        let entry = {};
	entry['end'] = todate;
        entry['start'] = fromdate;
        deliveryfull.push(entry);
      }
    }
    let receptionfull = [];
    if (appcontext && appcontext['bookingdata'] && appcontext['bookingdata']['receptioncapacityfullperiods']) {
      let fpfull = appcontext['bookingdata']['receptioncapacityfullperiods'];
      for (let i=0; i<fpfull.length; i++) {
	let todate = dateparse(fpfull[i]['to']);
	let fromdate = dateparse(fpfull[i]['from']);
        let entry = {};
	entry['end'] = todate;
        entry['start'] = fromdate;
        receptionfull.push(entry);
      }
    }

   console.log("Cap full %j, Recep full %j", deliveryfull, receptionfull);

    function validate(then) {
        let ok = true;
        if (!formdata.formdata['aapenretur'] && !formdata.formdata.returnflight) {
            console.log("Formdata: %j", formdata.formdata);
            alerthandler.addAlert(2, 'danger', "Velg returflight", "returnflightalert");
            ok = false;
        }
        if (typeof formdata.formdata['handluggageonly'] === 'undefined') {
            console.log("Formdata: %j", formdata.formdata);
            alerthandler.addAlert(2, 'danger', "Velg om du reiser med innsjekket bagasje eller ikke", 'luggagealerts');
            ok = false;
        }
            
        if (!formdata.formdata.departuredate) {
            alerthandler.addAlert(2, 'danger', "Velg avreisedato", "datealerts");
            ok = false;
        }
        if (!formdata.formdata['aapenretur'] && !formdata.formdata['returndate'] ) {
            alerthandler.addAlert(2, 'danger', "Velg enten ankomstdato eller åpen retur");
            ok = false;
        }

        then(ok);
    }

    function nextStep() {
        checkDropin();
        window.sessionStorage.setItem('form_step2', JSON.stringify(formdata.formdata));
        const success = function (validation) {
            if (!validation) {
                console.log("Invalid form");
                setValidStep(2, false);
                return false;
            } else {
                console.log("Valid form");
                console.log("Setting valid step to 3");
                setValidStep(2, true);
                return navigate("../3");
            }
        };
        return validate(success);
    }

    const setOpenReturn = (e) => {
        if (formdata.formdata['aapenretur']) {
            formdata.setFormData('aapenretur', false);
            appcontext.setOpenReturn(false);

            if (formdata.formdata['departuredate'] && formdata.formdata['returndate']) {
                let retdate = formdata.formdata['returndate'];
                let depdate = formdata.formdata['departuredate'];
                var cycles = (retdate-depdate)/(1000*60*60*24);
                let days = Math.ceil(cycles);
                appcontext.setDays(days);
            }


        } else {
            appcontext.setOpenReturn(true);
            formdata.setFormData('aapenretur', true);
        }
    };

    const setReturnFlight = (e) => {
        let returnflight = e.target.value;
        setReturnFlightOk(true);
        formdata.setFormData('returnflight', returnflight);
    };

    const handleReturnBlur = (e) => {
        let returnflight = e.target.value;
        if (formdata.formdata['aapenretur']) {
            setReturnFlightOk(true);
        } else if (!returnflight) {
            setReturnFlightOk(true);
        } else if (! window.validflights) {
            setReturnFlightOk(true);
        } else if (! window.validflights[returnflight]) {
            setReturnFlightOk(false);
        } else {
            setReturnFlightOk(true);
        }
    }

    const setHandluggageOnly = (e) => {
        formdata.setFormData('handluggageonly', parseInt(e.target.value));
    };


  const setDepartureDate = (date) => {
    // This handles the "00:00" thing a bit
    let now = new Date();
    if (date && date < now) {
        date = now;
    }

    formdata.setFormData('departuredate', date);

    if (formdata.formdata['aapenretur'] || !formdata.formdata['returndate']) {
        appcontext.setDepDate(date);
        appcontext.setDays(0);
    } else {
        let depdate = date;
        let retdate = formdata.formdata['returndate'];
        var cycles = (retdate-depdate)/(1000*60*60*24);
        let days = Math.ceil(cycles);

        appcontext.setDepDate(date);
        appcontext.setDays(days);
    }
  }


  const checkDropin = () => {
      let now = new Date();
      let dep = formdata.formdata['departuredate'];
      console.log("Checking dropin against " + dep);
      if (dep && startOfDay(dep).getTime() === startOfDay(now).getTime()) {
          console.log("Det er i dag");
          if (dep.getHours() < 18 && now.getHours() > 5) {
              console.log("Det er dropin");
              appcontext.setDropin(true);
          } else {
              console.log("Det er ikke dropin");
              appcontext.setDropin(false);
          }
      } else {
              console.log("Det er ikke i dag, ikke dropin");
              appcontext.setDropin(false);
      }
  }

  const blurDates = (e) => {
      console.log("Blurring dates");
      // Slight validation of dates
      if (formdata.formdata['returndate'] && !formdata.formdata['aapenretur'] && formdata.formdata['departuredate']) {
          if (formdata.formdata['returndate'] < formdata.formdata['departuredate']) {
            alerthandler.addAlert(2, 'danger', "Avreise må være før retur", "datealerts");
          }
      }
      // Check for dropin
      console.log("Check dropin");
      checkDropin();

      console.log("Check for zero");
      // Check for unreasonable return dates
      let ret = formdata.formdata['returndate'];
      let dep = formdata.formdata['departuredate'];
      if (ret) {
          let hours = ret.getHours();
          if (hours <= 7) {
              // setReturnDateOk(false);
          }
      }

      // Warn for dates of type 00:00
      if (dep && dep.getHours() === 0 && dep.getMinutes() === 0) {
          console.log("Dep is zero");
          setZeroWarning(true);
      } else if (ret && ret.getHours() === 0 && ret.getMinutes() === 0) {
          console.log("Ret is zero");
          setZeroWarning(true);
      } else {
          console.log("Do not warn");
          setZeroWarning(false);
      }

   }

  const filterDepartureTime = (time) => {
      const now = new Date();
      if (time <= now) return false;
      if (!formdata.formdata['aapenretur'] && formdata.formdata['returndate']) {
          if (formdata.formdata['returndate'] <= time)  {
              return false;
          }
      }
      return true;
  };
  const setReturnDate = (date) => {
    // This handles the "00:00" thing a bit
    let now = new Date();
    if (date && date < now) {
        date = now;
    }

    appcontext.setRetDate(date);
    formdata.setFormData('returndate', date);
    if (! formdata.formdata['departuredate']) {
        appcontext.setDays(0);
    } else {
        let retdate = date;
        let depdate = formdata.formdata['departuredate'];
        var cycles = (retdate-depdate)/(1000*60*60*24);
        let days = Math.ceil(cycles);
        appcontext.setDays(days);
    }
    
  }

  const filterReturnTime = (time) => {
      const now = new Date();
      if (time <= now) return false;
      if (formdata.formdata['departuredate']) {
          if (time <= formdata.formdata['departuredate'])  {
              return false;
          }
      }
      return true;
  };

  const getDefaultReturnDate = () => {
      if (formdata.formdata['aapenretur']) return "";
      return formdata.formdata['returndate'];
  };

  const getMaxDepartureDate = () => {
      let returndate = formdata.formdata['aapenretur'] ? null : formdata.formdata['returndate'];
      if (appcontext.servicedate) {
          return appcontext.servicedate;
      }
      return returndate;
  };

  const getMinReturnDate = () => {
      if (appcontext.servicedate) {
          return appcontext.servicedate;
      }
      return formdata.formdata['departuredate'];
  };

  return (
  <div   className={"step step2"} >

   <h2>Din reise</h2>
   <Row>
    <Col>
    <Form.Group >
    <Form.Label for="departuredate">Tid for avlevering av bil: </Form.Label>
       <DatePicker 
           id="departuredate" locale="nb" name="departuredate"
           strictParsing
           placeholderText="Velg avreisedato og klokkeslett"
           className="form-control"
           autoComplete="off"
           selected={formdata.formdata['departuredate'] ? formdata.formdata['departuredate'] : ""} 
           openToDate={ appcontext.servicedate ? appcontext.servicedate : new Date()}
           minDate={new Date()} 
           maxDate={getMaxDepartureDate()}
           dateFormat="do MMM yyyy 'kl.' HH:mm"
           timeFormat="HH:mm"
           onChange={setDepartureDate}
           filterTime={filterDepartureTime}
           showTimeSelect
           timeIntervals={5}
           onCalendarClose={ blurDates }
	   excludeDateIntervals={receptionfull}
           fixedHeight 
       />
    </Form.Group>
   </Col>
  </Row>
  <Row>
   <Col>
    <Form.Group >
    <Form.Label for="returndate"> Retur: </Form.Label>
       <DatePicker 
          id="returndate" locale="nb" name="returndate"  
          placeholderText={ formdata.formdata['aapenretur'] ? "Åpen retur" : "Velg returdato og klokkeslett"}
          strictParsing
          className="form-control"
          autoComplete="off"
          selected={getDefaultReturnDate()}
          openToDate={appcontext.servicedate ? appcontext.servicedate : (formdata.formdata['departuredate'] ? formdata.formdata['departuredate'] : new Date() ) }
          minDate={getMinReturnDate()}
          dateFormat="do MMM yyyy 'kl.' HH:mm"
          timeFormat="HH:mm"
          timeIntervals={5}
          onChange={setReturnDate}
          disabled={ formdata.formdata['aapenretur']}
          showTimeSelect
          filterTime={filterReturnTime}
          onCalendarClose={ blurDates }
	  excludeDateIntervals={deliveryfull}
          fixedHeight 
       />
    </Form.Group>
    </Col>
  </Row>
  <Row>
   <Col>
    <Form.Group>
    <Form.Label for="returnflight"> Flightnr. retur: </Form.Label>
     <Form.Control 
          type="text" 
          autoComplete="off"
          list="validflightlist"
          placeholderText="Flightnr retur"
          value={formdata.formdata['aapenretur']    ? "" : formdata.formdata['returnflight']} 
          disabled={formdata.formdata['aapenretur']}  
          onChange={setReturnFlight}
          onBlur={handleReturnBlur}
          />
    </Form.Group>
  </Col>
 </Row>
 <Row> 
  <Col>
    <Form.Group>
     <Form.Check label="Ukjent returdato" inline  type="checkbox" onChange={setOpenReturn}  checked={formdata.formdata['aapenretur'] ? true : false} id="aapenretur" name="aapenretur" value="aapenretur" />
    </Form.Group>
  </Col>
 </Row>

 <Row>
   <div class="returnflightcheck" style={{ display: (returnflightok ? "none" : "block"), fontStyle:"italic"}}>
    <span> Er du sikker på at flight-nummeret for retur-flighten er korrekt? Sjekk flybilletten for landingen. </span>
   </div>
    <div id="dropinwarning" style={{display:(appcontext.dropin ? "block" : "none") , fontStyle:"italic"}}>
      Et drop-in gebyr på {dropinprice} NOK vil bli lagt til på bestillinger som gjøres etter klokken 0500 med levering av bil samme dag før klokken 1800
    </div>
    <div id="zerowarning" style={{display:(zerowarning ? "block" : "none") , fontStyle:"italic"}}>
      Husk å sjekke både dato og klokkeslett på flybiletten.
    </div>
 </Row>


 <Row>
  <Col>
  <h2 className="regular"> Reiser du med innsjekket bagasje?</h2>
  <Form.Group className="luggagediv">
   <Form.Check label="Nei"  onChange={setHandluggageOnly} type="radio" checked={ formdata.formdata['handluggageonly'] } name="handluggageonly" value="1" />
   <Form.Check label="Ja"  onChange={setHandluggageOnly} type="radio" checked={formdata.formdata['handluggageonly'] === 0 ? true : false } name="handluggageonly" value="0" />
 </Form.Group>

 <div class="luggage-explanation">
          <div class="sizer" style={{display: typeof(formdata.formdata['handluggageonly']) === 'undefined' ? "block" : "none", visibility:"hidden"}}>
                  Bilen er på plass på terminalen ca 10 minutter etter at flyet faktisk har landet. Vi følger med på reelle landingstidspunkter
          </div>
          <div class="handluggageonly" style={{display: formdata.formdata['handluggageonly'] ? "block" : "none", fontStyle:"italic"}}>
                  Bilen er på plass på terminalen ca <b>10 minutter</b> etter at flyet faktisk har landet. Vi følger med på reelle landingstidspunkter.
          </div>
          <div class="checkinluggage" style={{display:formdata.formdata['handluggageonly']===0 ? "block" : "none", fontStyle:"italic"}} >
                  Bilen er på plass på terminalen ca <b>30 minutter</b> etter at flyet faktisk har landet. Vi følger med på reelle landingstidspunkter.
          </div>
 </div>

  </Col>
 </Row>


   <Row>
    <Col>
     <Button  className="me-2 mb-2" onClick={() => nextStep()}>
      Fortsett til neste steg
     </Button>

    </Col>
   </Row>

<datalist id="validflightlist">
 { window.validflights ? Object.keys(window.validflights).map( (el) => <option key={el}>{el}</option> ) : [] }
</datalist> 
 

  </div>);
});

