import React  from 'react';
import {  Row, Col } from 'react-bootstrap';
import { useApplicationContext } from '../appcontext';

export const Step5= React.memo( (props) => {
    const appcontext = useApplicationContext();

    React.useEffect( () => {
        if (appcontext.lastorder) {
            window.sessionStorage.removeItem('form_step1');
            window.sessionStorage.removeItem('form_step2');
            window.sessionStorage.removeItem('form_step3');
            window.sessionStorage.removeItem('form_step4');
            appcontext.formdata['step1'].clearFormData();
            appcontext.formdata['step2'].clearFormData();
            appcontext.formdata['step3'].clearFormData();
            appcontext.formdata['step4'].clearFormData();

            appcontext.setDays(0);
            appcontext.setDepDate(null);
            appcontext.setRetDate(null);
            appcontext.setOpenReturn(null);
            appcontext.setDropin(false);
            appcontext.setServiceDate(null);

        }
    }, 
    // We don't use "appcontext", and it is an eternal loop if we do so
    // eslint-disable-next-line
    [appcontext.lastorder]);
     

    console.log("rendering step 5");

    return (
    <>
     <div   className={"step step5 order-confirmation"} >
    <Row>
     <Col>
    <h1> Takk for din bestilling! { appcontext.lastorder ? <span> - Ordrenr { appcontext.lastorder.id } </span> : [] }    </h1>
     </Col>
    </Row>

    <Row>
     <Col>
  	<p> 
  		Du vil nå motta en ordrebekreftelse per e-post. Det kan hende noen e-post-klienter vil legge denne mailen i spamfilteret.
  	</p>
        <p> Du kan se og redigere på dine bestillinger på <a href="https://www.flightpark.no/parkering-gardermoen/user/min-side/">din profilside</a> hos Flightpark Gardermoen</p>
     </Col>
   </Row>
    </div>



</>

    );
});

