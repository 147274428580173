import React  from 'react';
import { useNavigate } from "react-router-dom";
import { Button,  Row, Col, Form } from 'react-bootstrap';
import { useApplicationContext } from '../appcontext';
import { TermsConditionsPopup } from '../TermsConditionsPopup';

export const Step4= React.memo( ({ alerthandler, formdata, setValidStep, completeOrder}) => {
    const navigate = useNavigate();
    const appcontext = useApplicationContext();
    const [showterms, setShowTerms] = React.useState(false);

//    let [eudate, setEudate] = React.useState(false); // Not currently in use

    function validate(then) {
        let ok = true;
        if (!formdata.formdata['approve'] ) {
           alerthandler.addAlert(4, 'danger', 'Vennligst les og kryss av for godkjennelse av våre bestillings- og betalingsvilkår!', "approvealerts1");
           ok = false;
        }
        if (!formdata.formdata['approveextracost']) {
           alerthandler.addAlert(4, 'danger', 'Vennligst si fra om du er inneforstått med en mulig ekstra kostnad for parkering ved hjemkomst.', "approvealerts2");
           ok = false;
        }
         return then(ok);
    }

    React.useEffect( () => {
        if (appcontext.user) {
            let newsletter = 1*appcontext.user.newsletter;
            if (newsletter) {
                formdata.setFormData('newsletter', newsletter);
            }
        }
    }, 
    // We don't actually *use* formdata, this is just first init
    // eslint-disable-next-line 
    [appcontext.user]);

    function nextStep() {
        window.sessionStorage.setItem('form_step4', JSON.stringify(formdata.formdata));
        alerthandler.clearAlerts();
        const success = function (validation) {
            if (!validation) {
                console.log("Invalid form 4");
                setValidStep(4, false);
                return false;
            } else {
                console.log("Valid form 4, completing order");
                return completeOrder(function (result) {
                    if (result['ok']) {
                        appcontext.setLastOrder(result['order']);
                        console.log("Setting valid step to 4");
                        setValidStep(4, true);
                        return navigate("../5");
                    } else {
                        alerthandler.addAlert(4, 'danger', "Kunne ikke fullføre bestillingen: " + result['msg']);
                    }
                });
            }
        };
        return validate(success);
    }


    function showTerms() {
        setShowTerms(true);
    }


    return (
    <>
    <div   className={"step step4 user-information"} >

       <input type="hidden" id="eudate" value='' />

    <Row>
     <Col>
    <h1> Tilleggsinformasjon </h1>
     </Col>
    </Row>
 
   <Row>
    <Col>
      <Form.Label for="comment">Kommentarer eller tilleggsinformasjon </Form.Label>
      <Form.Control 
                    as="textarea" rows={3} name="comment" id="comment" style={{width: "100%"}} 
                    value={formdata.formdata['comment']}
                    onChange={(e) => formdata.setFormData('comment', e.target.value) } />
    </Col>
   </Row>
   <Row>
    <Col>
        <p>
				Avbestilling av parkering eller andre tjenester må gjøres senest ett døgn før avreise.<br />
			Betaling gjør du med kort (vi tar de fleste typer kort) når du henter bilen ved hjemreise.
        </p>
    </Col>
   </Row>
   <Row className="consent">
    <Col>
     <Form.Group>

       <Form.Check id="approve" type="checkbox">
         <Form.Check.Input type="checkbox"  name="approve"
           value="godkjenn" 
           checked={formdata.formdata['approve'] === "godkjenn"}
           onChange={(e) => { alerthandler.removeAlertGroup('approvealerts1'); formdata.setFormData('approve', (formdata.formdata['approve'] === "godkjenn" ? false: "godkjenn"))}  }

         />
         <Form.Check.Label> Jeg har lest og godtar Flightparks <button class="link-button" onClick={showTerms}>bestillings- og betalingsvilkår</button>.</Form.Check.Label>
       </Form.Check>

        <Form.Check id="approveextracost" type="checkbox">
            <Form.Check.Input type="checkbox"
              value="godkjenntillegg"
              name="approveextracost"
              checked={formdata.formdata['approveextracost'] }
              onChange={(e) => { alerthandler.removeAlertGroup('approvealerts2');  formdata.setFormData('approveextracost', !formdata.formdata['approveextracost']) }}
            />
            <Form.Check.Label>
               Jeg er inneforstått med at det kan tilkomme et tillegg på ca 30-90 kr ved hjemkomst. Dette betales på automat ved utkjøring eller på <a rel="noreferrer" href="https://www.autopay.io" target="_blank">www.autopay.io</a> innen 48 timer. Les <button className="link-button"  onClick={showTerms}>våre vilkår</button> for mer utfyllende informasjon om dette.
            </Form.Check.Label>
        </Form.Check>

        <Form.Check value={1} name="newsletter" id="newsletter" type="checkbox" > 
            <Form.Check.Input type="checkbox" 
              name="newsletter"
              value={1}
              checked={formdata.formdata['newsletter']}
              onChange={(e) => formdata.setFormData('newsletter', (formdata.formdata['newsletter']? 0 : 1)) }
            />
            <Form.Check.Label>Ja takk, jeg vil gjerne motta tilbudsmail fra Flight Park.</Form.Check.Label>
        </Form.Check>

    </Form.Group>

    </Col>
   </Row>

   { /* No simulation in live env } 
   <Row>
    <Col>
     <div key="1"  className="mb-3">
          <h2>Choose how to treat this order - either by simulating success or failure, or by actually inserting the order</h2>
          <Form.Group>
          <Form.Check
            inline
            label="Actually place an order"
            name="simulation"
            value="none"
            checked={! appcontext.simulated}
            onChange={() => appcontext.setSimulated(false) }
            type="radio"
            id="sim-none"
          />
          <Form.Check
            inline
            label="failure"
            name="simulation"
            value="failure"
            checked={appcontext.simulated=== "failure"}
            onChange={() => appcontext.setSimulated('failure') }
            type="radio"
            id="sim-fail"
          />
          <Form.Check
            inline
            label="success"
            value="success"
            checked={appcontext.simulated=== "success"}
            onChange={() => appcontext.setSimulated('success') }
            type="radio"
            id="sim-success"
          />
         </Form.Group>
     </div>

    </Col>
   </Row>
   { */ }


    <Row>
     <Col>
      <Button  className="me-2 mb-2" onClick={ nextStep } >
        Send bestilling
     </Button>
     </Col>
    </Row>


   <TermsConditionsPopup showterms={showterms} setShowTerms={setShowTerms} />

   </div>
   </>

    );
});

