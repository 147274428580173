import React  from 'react';

import { Row, Col, Button, Form, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { useApplicationContext } from './appcontext';
import { callFlightpark } from './restcalls';

export function LoginPopup ({ username, show, hideaction, afterlogin}) {
    function onHide  (e) {
        hideaction();
    }
    function whendone () {
        if (afterlogin) {
            afterlogin();
        }
    }
  return (
      <Modal size="lg" show={show} fullscreen='md-down'  onHide={onHide} >
        <Modal.Body>
         <LoginForm username={username} done={whendone} />
        </Modal.Body>
      </Modal>
  );
}

export const LogoutForm = () => {
    let appcontext = useApplicationContext();
    if (!appcontext.user) {
        appcontext.setUser(null);
        return <div> Du er logget ut. </div>
    }
    const lgoutaction = (e) => {
        e.preventDefault();
        return callFlightpark('logout', [], function (data) {
            appcontext.setFpid(data['nonce']);
            window.sessionStorage.removeItem('form_step2');
            window.sessionStorage.removeItem('form_step3');
            window.sessionStorage.removeItem('form_step4');
            appcontext.setUser(null);
            appcontext.formdata['step2'].clearFormData();
            appcontext.formdata['step3'].clearFormData();
            appcontext.formdata['step4'].clearFormData();
            appcontext.setValidStep(1);
        });
    }

    return (
    <div>
       <Button onClick={lgoutaction}>Logg ut {appcontext.user.email}</Button>
    </div>
    );
}

export const LoginForm = ({done}) =>  {
    let appcontext = useApplicationContext();

    let [loginusername, setLoginUserName] = React.useState(appcontext.username);

    let [loginerrortext, setLoginError] = React.useState("");
    let [forgeterrortext, setForgetError] = React.useState("");

    const handleEmailChange = (e) => {
        setLoginError("");
        setForgetError("");
        setLoginUserName(e.target.value);
    };


    const [password, setPassword] = React.useState("");
    const lgaction = (action, andthen, andfail) => (
        function (e) {
            setLoginError("");
            setForgetError("");
            e.preventDefault();
            const data = {};
            data['email'] = loginusername;
            data['forgotemail'] = loginusername;
            data['password'] = password;
            data['do'] = action;
            return callFlightpark(action, data, function (success) {
                if (andthen) return andthen(success);
            },
            function (failure) {
                if (andfail) return andfail(failure);
                console.log("Failure at " + action + ": %j", failure);
            },
            function (error) {
                if (andfail) return andfail(error);
                console.log("Error at " + action + ": %j", error);
            });
        });
    const forgetAction = lgaction('forgot', 
         function (success) {
             setForgetError("En e-post med et nytt midlertidig passord er sendt til din konto!");
         }, 
         function (failure) {
             setForgetError("Ukjent bruker, eller feil ved passord-tilbakestilling");
         }
    );
    const loginAction = lgaction('login', 
        function (result) {
         if (result['bookingcustomer']) {
                  appcontext.setUsername(result['bookingcustomer']['email']);
                  appcontext.setUser(result['bookingcustomer']);
                  if (done) {
                      return done();
                  }
              } else {
                  appcontext.setUsername(null);
                  appcontext.setUser(null);
              }
        },
        function (failure) {
            setLoginError("Feil brukernavn eller passord");
        }
        );

    return (
    <>
      <Row>
       <Col>
         <h2>Logg inn</h2>
         <Form onSubmit={loginAction}>
          <Form.Group className="mb-3" controlId="formUsername">
           <Form.Control id="emailentry" value={loginusername} onChange={handleEmailChange}
            placeholder="Skriv inn e-post"
            name="email"
            type="email" />
           </Form.Group>
           <Form.Group className="mb-3" controlId="formPassword">
             <Form.Control id="passwordentry" autoFocus value={password} onChange={(e) => setPassword(e.target.value) }
              placeholder="Passord"
              name="password"
              type="password" />
          </Form.Group>
          <Button type="submit">Login</Button>
          <div id="loginerror" style={{height: "2rem"}}>{loginerrortext}</div>
         </Form>
        </Col>
        <Col>
         <Form onSubmit={forgetAction}>
          <Form.Group className="mb-3" controlId="formForgotUsername">
           <Form.Label>E-post</Form.Label>
           <Form.Control id="forgotemailentry" value={loginusername} onChange={handleEmailChange}
            placeholder="Skriv inn e-post"
            name="email"
            type="email" />
          </Form.Group>
          <Button type="submit">Send passord</Button>
          <div id="forgoterror" style={{height: "2rem"}}>{forgeterrortext}</div>
         </Form>
        </Col>
       </Row>
    </>
    );

}
