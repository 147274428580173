import React  from 'react';
import {  useNavigate, useParams} from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { useApplicationContext } from './appcontext';
import { callFlightpark, completeorder } from './restcalls';

import { LoginPopup } from './loginform';
import { useAlertHandler, Alerts } from "./alerthandler";

import { PriceWidget } from "./PriceWidget";
import { Step1 } from "./steps/Step1.js";
import { Step2 } from "./steps/Step2.js";
import { Step3 } from "./steps/Step3.js";
import { Step4 } from "./steps/Step4.js";
import { Step5 } from "./steps/Step5.js";


export function Steps ({setCurrentStep}) {
    let { step }= useParams();

    const appcontext = useApplicationContext();
    const navigate = useNavigate();
    const alerthandler = useAlertHandler();

    const [showlogin, setShowlogin] = React.useState(false);

    const completeOrder = (continuation) => {
        completeorder(appcontext, continuation);
    };

    let stepvalue = 0;
    if (step === 'login') {
        stepvalue = 1;
    } else {
        stepvalue = parseInt(step);
    }
    setCurrentStep(stepvalue);

    console.log("Valid step " + appcontext.validstep);
    console.log("Current step " + stepvalue);

    // Allow a step to declare itself valid or invalid and thus progress the wizard
    const setValidStep = (step, isvalid) => {
        if (isvalid) {
            // Valid, so progress to next step (or to the current highest)
            let nextstep = step+1;
            if (nextstep > appcontext.validstep) {
                appcontext.setValidStep(nextstep);
            }
        } else {
            // Invalid, so restrict to this step
            appcontext.setValidStep(step);
        }
    };

    // After an order has been completed, zapp to step 1
    React.useEffect( () => {
        if (appcontext.lastorder && stepvalue !== 5) {
            appcontext.setLastOrder(null);
            appcontext.setValidStep(1);
        }
    }, 
    // We do actually not depend on appcontext or the validstep  so ensure no snafus
    // eslint-disable-next-line
    [step, stepvalue,  appcontext.lastorder ]);

    React.useEffect( ()  => {
        console.log("Step is " + step + " and value " + stepvalue);
        if (appcontext.validstep>1 &&  stepvalue>1) {
            if (!appcontext.user) {
            // This checks for *all* steps if the user exists, unless logged in - not just once.
            // but that's a bit safer so let's keep it.
            console.log("Checking if user exists");
            callFlightpark('checkemail', {'email': appcontext.username, 'fpid' : appcontext.fpid },
                        function (data) {
                            console.log("We are on step 2 or bigger, not logged in and doing the call");
                            if (data['ok']) {
                                if (data['code'] === 'userexists') {
                                    console.log("User exists");
                                    appcontext.setUserexists(true);
                                } else if (data['code'] === 'newuser') {
                                    console.log("User is new");
                                    appcontext.setUserexists(false);
                                } else {
                                    // Reload the whole thing from scratch FIXME
                                    console.log("something error");
        
                                }
                            } else {
                                    // Reload the whole thing from scratch FIXME
                                    console.log("something error2");
                            }
                         });
            } else {
                appcontext.setUserexists(true);
            }
        }
        
    }, [appcontext.fpid, appcontext.user, appcontext.userexists, appcontext.username, step, stepvalue, appcontext.validstep]);


    React.useEffect( () => {
        if (stepvalue> 1) {
            if (appcontext.user) {
                console.log("We have an appcontext.user so we set setuserexists to true");
                appcontext.setUserexists(true);
            }
            if (!appcontext.username) {
                console.log("No username, so go back");
                appcontext.setValidStep(1);
            } else if (appcontext.user) {
                console.log("We are logged in, hooray!");
            } else if (!appcontext.user && appcontext.userexists) {
                console.log("No logged in user but userexists is %j", appcontext.userexists);
            } else if (appcontext.username && !appcontext.user && !appcontext.userexists) {
                console.log("New user");
            } else {
                console.log("Unreachable branch in second effect");
            }
        } else if (step === 'login' && !appcontext.user) {
            setShowlogin(true);
        } else {
            setShowlogin(false);
        }
        // we only depend on setValidStep, so not at all really
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, stepvalue, appcontext.username, appcontext.user, appcontext.setValidStep, appcontext.userexists]);


    React.useEffect(() => {
            if (stepvalue  > appcontext.validstep) {
              navigate("/bestill/" + appcontext.validstep.toString());
            } else {
                alerthandler.clearAlerts();
                console.log("step is valid");
            }
    }, 
     // We don't depend on the alerthandler above, so ignore exhaustive deps - otherwise the dep wil loop
     // eslint-disable-next-line react-hooks/exhaustive-deps 
    [step, appcontext.validstep, navigate ]);
    

    const afterlogin = () => {
        console.log("Running afterlogin");
        alerthandler.removeAlertGroup("emailalerts");
        alerthandler.removeAlertGroup("loginalerts");
        alerthandler.addAlert(step, 'info', "Innlogging ok!", "loginalerts");
        console.log("And we are logged in.");
        setShowlogin(false);
        console.log("Doing the sucess reload action on step " + step);
        navigate("../" + step);
    };
    const hidelogin = () => {
        console.log("Running hidelogin");
        /* Just reload - appcontext is set, but not soon enough.*/
        setShowlogin(false);
        alerthandler.removeAlertGroup("loginalerts");
        alerthandler.addAlert(1, 'warning', "Innlogging påkrevet", "emailalerts");
        console.log("Doing the failure reload action");
        navigate("../1");
    }

    return (
       <Container className="Steps">
        <Row>
         <Col>
          <Alerts step={step} alerthandler={alerthandler} />
         </Col>
        </Row>
        <Row>
         <Col lg={8}>
       {
           (stepvalue === 1) ?
            <Step1  alerthandler={alerthandler}  formdata={appcontext.formdata['step1']} setValidStep={setValidStep}/> :
           (stepvalue === 2) ?  
            <Step2 alerthandler={alerthandler}  formdata={appcontext.formdata['step2']} setValidStep={setValidStep} /> :
           (stepvalue === 3) ?  
            <Step3 alerthandler={alerthandler} setShowlogin={setShowlogin} formdata={appcontext.formdata['step3']} setValidStep={setValidStep} /> :
           (stepvalue === 4) ?  
            <Step4 alerthandler={alerthandler}  formdata={appcontext.formdata['step3']} setValidStep={setValidStep} completeOrder={completeOrder}/> :
            (stepvalue === 5) ?
            <Step5 /> :
            <div> Emptyness </div>
       }
         </Col>
         { stepvalue > 1  ? 
         <Col style={{display: appcontext.lastorder ? "none" : "block" }}  lg={4}>
          <PriceWidget />
        </Col>
        : [] }
       </Row>

        <LoginPopup show={showlogin} hideaction={ hidelogin } afterlogin={afterlogin}  />
       </Container>);
}
