import React, { useState,useContext } from 'react';
import { useFormData } from "./formdata";
import { porschelist } from "./porschelist";

export const AppContext = React.createContext(null);
AppContext.displayName = "Application Context";


export const ApplicationContext = React.memo(function (props) {

const [fpid, setFpid] = useState(null);
const [user, setUser] = useState(null);
const [init, setInit] = useState(null);
const [username, setUsername] = useState("");
const [userexists, setUserexists] = useState("");
const [validstep, setValidStep] = useState(1);
const [dropin, setDropin] = useState(false);

const [days, setDays] = useState(0);
const [openreturn, setOpenReturn] = useState(false);
const [depdate, setDepDate] = useState(null);
const [retdate, setRetDate] = useState(null);

const [companydiscount, setCompanyDiscount] = useState(0);

const [lastorder, setLastOrder] = useState(null);

const [bookingdata, setBookingData] = useState({});

const [servicedate, setServiceDate] = useState(null);

const step1 = useFormData({"boomer": "ok"});
const step2 = useFormData({"seconds": "yes"});
const step3 = useFormData({"thirds": "3"});
const step4 = useFormData({"fourth": "4"});

// Always use the "Drive to Porsche program. Specs are set on complete
const programs = useFormData({36: 36 });
const [largecar, setLargeCar] = useState(false);


const formdata = { 'step1': step1, 'step2': step2, 'step3': step3, 'step4': step4};

//const [simulated, setSimulated] = useState("failure");
const [simulated, setSimulated] = useState(false);

const appcontext  = { 
    'user': user, 'setUser': setUser, 
    'init': init, 'setInit': setInit, 
    'fpid':fpid, 'setFpid': setFpid,

    'bookingdata': bookingdata, 'setBookingData' : setBookingData,

    'username' : username,
    'setUsername': setUsername,

    // not currenlty used, can be used to cache status of username if managed
    'userexists' : userexists, 'setUserexists' : setUserexists,

    'validstep' : validstep, 'setValidStep': setValidStep,

    'days': days, 'setDays': setDays,
    'depdate': depdate, 'setDepDate':  setDepDate,
    'retdate': retdate, 'setRetDate':  setRetDate,
    'openreturn': openreturn, 'setOpenReturn': setOpenReturn,
    'dropin' : dropin, 'setDropin': setDropin,

    'lastorder': lastorder, 'setLastOrder': setLastOrder,

    'companydiscount': companydiscount, 'setCompanyDiscount': setCompanyDiscount,
    'programs': programs,

    'formdata' : formdata,

    'largecar' : largecar, 'setLargeCar': setLargeCar,

    'simulated': simulated, 'setSimulated': setSimulated,

    'porschelist': porschelist,

    'servicedate': servicedate, 'setServiceDate': setServiceDate

    };
    return (
      <AppContext.Provider value={appcontext}>
         { props.children}
      </AppContext.Provider>
 );
});


export function useApplicationContext() {
    return useContext(AppContext);
}


