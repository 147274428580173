import React  from 'react';
import { Alert } from 'react-bootstrap';
import { useApplicationContext } from './appcontext';

import {parseISO as dateparse} from 'date-fns';
import {format as dateformat} from 'date-fns/esm';
import {nb} from 'date-fns/esm/locale'

export const FPAlerts = React.memo(function (props) {
    const appcontext = useApplicationContext();
    if (!appcontext || !appcontext['bookingdata']) return null;

    return (
        <>
	    <FPFull fullperiods={appcontext.bookingdata['fullperiods']} />
        </>
    );
});

const FPFull = React.memo(({fullperiods=[]}) => {
	if (! fullperiods) return null;
	let futureperiods = [];
	for (let i=0; i<fullperiods.length; i++) {
		let entry = fullperiods[i];
		 let todate = dateparse(entry['to']);
		 let fromdate = dateparse(entry['from']);

		if (todate > new Date()) {
		   entry['todate'] = todate;
		   entry['fromdate'] = fromdate;
		   futureperiods.push(entry);
		}
	}
	return (
	<>
	 { futureperiods.map(({fromdate, todate}) => (

            <Alert variant="danger">
		 Det er dessverre helt fullt hos Flightpark Gardermoen i tidsrommet&nbsp;  
		 <b>{dateformat(fromdate, "do MMM yyyy 'kl.' HH:mm",{locale: nb})}-{dateformat(todate, "do MMM yyyy 'kl.' HH:mm",{locale: nb})}</b> - vi kan derfor ikke ta i mot noen bestillinger i denne perioden.

	   </Alert>
	   ))
	 }
	</>
	)
});

export default FPAlerts ;

