import React  from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";

import { Nav, Navbar, Container } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './porsche-fonts.css';

import { ApplicationContext, useApplicationContext } from './appcontext';
import { Init } from './Init';
import { Steps } from "./Steps";
import { LogoutForm } from "./loginform";

import FPAlerts from "./FPalerts";


const PageNotFound = React.memo(function () {
    return (
      <p> This page intentionally left blank </p>
    );
});


function LoginLink() {
    const appcontext = useApplicationContext();
    if (appcontext.user) {
        return  <Link style={{"alignSelf": "flex-end", "marginLeft": "auto"}} className="nav-link" to="/logout"> Logg ut { appcontext.username ? appcontext.username : "None"} </Link>;
    } else  {
        return  <Link style={{"alignSelf": "flex-end", "marginLeft": "auto"}} className="nav-link" to="/bestill/login"> Logg inn</Link>;
    }
}


const App = React.memo(function (props)  {
  return (
  <>
  <ApplicationContext>
    <Init />
     <BrowserRouter basename={process.env.PUBLIC_URL}>
       <Main />
     </BrowserRouter>
  </ApplicationContext>
  </>
  )
});

const Main = React.memo(function (props) {
    const appcontext = useApplicationContext();
    const [currentstep, setCurrentStep] = React.useState(0);
    return(
<>
<Navbar bg="light" expand="lg">
  <Container>
   
    <Navbar.Brand href="/">Porsche Airport Service</Navbar.Brand>


    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end">
      <Nav className="me-auto" style={{width: "100%"}} >
        <Link className={"nav-link"
                          + ( currentstep === 1 ? " active " : "") 
                        }
        to="/bestill/1">1. Din avtale</Link>
        <Link disabled={appcontext.validstep < 2} 
              className={"nav-link" 
                     + ((appcontext.validstep < 2) ? " disabled" : "")
                     + ( currentstep === 2 ? " active " : "") 
                     }
              to="/bestill/2">2. Bestill parkering</Link>
        <Link disabled={appcontext.validstep < 3} 
              className={"nav-link"
                          + ((appcontext.validstep < 3) ? " disabled" : "")
                          + ( currentstep === 3 ? " active " : "") 
                         }
              to="/bestill/3">3. Dine data</Link>
        <Link disabled={appcontext.validstep < 4}
              className={"nav-link"  
                         + ((appcontext.validstep < 4) ? " disabled" : "")
                         + (currentstep === 4 ? " active " : "")
                          }
              to="/bestill/4">4. Send bestilling</Link>
        <Link disabled={appcontext.validstep < 5}
              className={"nav-link"  
                         + ((appcontext.validstep < 5) ? " disabled" : "")
                         + (currentstep === 5 ? " active " : "")
                          }
              to="/bestill/4">5. Bekreftelse</Link>

        <LoginLink />
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>



    <Container className="mt-5">

    <FPAlerts />

       <Routes>
         <Route path="/" element={<Navigate to="/bestill/1" />} />
         <Route path="/bestill" >
          <Route path="" element={<Navigate to="/bestill/1" />} />
          <Route path=":step" element={<Steps setCurrentStep={setCurrentStep} />} />
         </Route>
   
         <Route path="/logout" element={<LogoutForm />} />
         <Route path="*" element={<PageNotFound />} />
       </Routes>


    </Container>


</>

 );
});


export default App;

